
<style scoped>
	.container {
		width: 100%;
		min-width: 1320px;
	}
	.box1 {
		position: relative;
		width: 100%;
		height: 435px;
		background-image: url(../src/assets/images/bg2.png);
		background-repeat: no-repeat;
	}
	
	.title {
		flex-direction: column;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		color: #fff;
	}
	.title > p:nth-child(1) {
		font-size: 31px;
		letter-spacing: 3px;
	}
	.title > p:nth-child(2) {
		font-size: 16px;
		margin-top: 40px;
	}
</style>

<template>
	<div class="container">
		<div class="box1">
			<TopTab></TopTab>
			<div class="title flexCenter">
				<p>{{topTitle}}</p>
				<p>{{topTitle2}}</p>
			</div>
		</div>
	</div>
</template>

<script>
	import TopTab from "./topTab.vue";
	export default {
		data() {
			return {
				
				navIndex: 0,
				path: this.$store.state.path,
			}
		},
		props: ["topTitle", "topTitle2"],
		methods: {
			selectNav(index, path) {
				this.navIndex = index;
				this.$router.push(path);
			}
		},
		components: {TopTab}
	}
</script>

<style>
</style>
