
<style scoped>
.con-title {
  width: 1320px;
  padding-top: 70px;
  margin: 0 auto;
  text-align: center;
  flex-direction: column;
}
.con-title > p {
  font-size: 32px;
  color: #75b9f6;
}
.con-title > div {
  width: 45px;
  height: 13px;
  background: #75b9f6;
  margin: 28px 0 0;
}
/*  */
.content {
  width: 1320px;
  height: 750px;
  margin: 0 auto;
  padding: 145px 20px;
}
.con-item {
  width: calc((100% - 40px) / 3);
  height: 485px;
  margin-right: 20px;
  box-shadow: 0 0 20px #f9f9f9;
  transition: height 0.5s;
}
.content > div:last-child {
  margin-right: 0;
}
.con-item > img {
  width: 100%;
  height: 245px;
}
.con-item:hover {
  height: 600px;
  width: 480px;
  /* transform: translateY(-120px); */
}
.con-item > div {
  padding: 23px 23px 10px;
}
.con-p1 {
  width: 100%;
  font-size: 25px;
  color: #252b3a;
  margin: 29px 0 35px;
}
.con-p2 {
  color: #666a75;
  font-size: 18px;
  line-height: 35px;
}
.con-p3 {
  color: #666a75;
  font-size: 17px;
  margin-top: 10px;
}
/*  */
.content2 {
  width: 1320px;
  padding: 50px 20px 145px;
  margin: 0 auto;
}
.content2 > div:last-child {
  margin-bottom: 0;
}
.con2-item {
  height: 398px;
  width: 100%;
  box-shadow: 0 0 10px #fafafa;
  margin-bottom: 48px;
}
.con2-item > img {
  width: 428px;
  height: 100%;
}
.con2-item-right {
  width: calc(100% - 428px);
  height: 100%;
  padding: 50px;
}
.con2-p1 {
  width: 100%;
  color: #333;
  font-size: 25px;
}
.con2-p2 {
  color: #666a75;
  font-size: 21px;
  margin-top: 32px;
  min-height: 220px;
  letter-spacing: 3px;
  line-height: 35px;
}
.con2-btn {
  width: 100%;
}
.con2-btn > div {
  width: 155px;
  height: 37px;
  line-height: 37px;
  color: #fff;
  font-size: 18px;
  text-align: center;
  background: #75b9f6;
}
.con2-btn > p {
  color: #666a75;
  font-size: 17px;
}
.no-data {
  text-align: center;
  margin: 50px;
  color: #999;
}
.no-data-list {
  width: 1300px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
}
.el-carousel {
  min-width: 1320px;
  max-width: 1500px;
  height: 600px;
  margin: 0 auto;
}
.el-carousel >>> .el-carousel__container {
  width: 100%;
  height: 100%;
}
.new-box {
  width: 100%;
  height: 100%;
}
.new-box > img {
  width: 100%;
  height: 50%;
}
.new-box > div {
  padding: 0px 20px;
}
/* .el-carousel__item img {
  width: 100%;
} */
/deep/.el-carousel__indicators {
  display: none;
}
</style>

<template>
  <div>
    <Top :topTitle="topTitle"></Top>
    <div class="con-title flexCenter">
      <p>企业资讯</p>
      <div></div>
    </div>
    <el-carousel :interval="4000" type="card" :autoplay="false">
      <el-carousel-item v-for="(item, index) in list" :key="index">
        <div class="new-box" @click="toPath(item.id, item.type)">
          <img :src="item.image" />
          <div>
            <p class="con-p1 ell1">{{ item.title }}</p>
            <p class="con-p2">{{ item.introduction }}</p>
            <p class="con-p3">{{ item.create_time }}</p>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
    <div class="con-title flexCenter">
      <p>行业资讯</p>
      <div></div>
    </div>
    <div class="content2" v-if="list1.length > 0">
      <div
        class="con2-item flex"
        v-for="(item, index) in list1"
        :key="index"
        @click="toPath(item.id, item.type)"
      >
        <img :src="item.image" :alt="item.title" v-if="item.image" />
        <div class="con2-item-right">
          <p class="con2-p1 ell1">{{ item.title }}</p>
          <p class="con2-p2">{{ item.introduction }}</p>
          <div class="con2-btn flex-between flex-y-center">
            <p>{{ item.create_time }}</p>
            <div>立即查看</div>
          </div>
        </div>
      </div>
      <div class="no-data-list">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 25]"
          :current-page="page1"
          :page-size="limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>

    <div class="no-data" v-else>暂无数据~~~</div>

    <bottom :minWidth="minWidth"></bottom>
  </div>
</template>

<script>
import Top from "../../components/top.vue";
import Bottom from "../../components/bottom.vue";
export default {
  data() {
    return {
      topTitle: "资讯动态",
      minWidth: 1320,

      list: [],
      page: 1, //页
      lastPage: 1,

      page1: 1, //页
      limit: 10, //条
      total: 0, //总数
      list1: [],
      lastPage1: 1, // 总页数
    };
  },
  created() {
    this.getNews();
    this.getNews1();
  },
  methods: {
    getNews() {
      this.http({
        method: "get",
        url: this.api.getNewsLIst,
        data: { page: this.page, type: 1, pageSize: 50 },
      }).then((res) => {
        this.lastPage = res.data.last_page;
        var list = this.list;
        if (list.length) {
          res.data.data.map(function (item) {
            list.push(item);
          });
        } else {
          list = res.data.data;
        }
        this.list = list;
      });
    },

    getNews1() {
      this.http({
        method: "get",
        url: this.api.getNewsLIst,
        data: { page: this.page1, type: 2, pageSize: this.limit },
      }).then((res) => {
        this.lastPage1 = res.data.last_page;
        this.total = res.data.total;
        this.list1 = res.data.data;
      });
    },

    handleSizeChange(val) {
      this.limit = val;
      this.page1 = 1;
      this.getNews1();
    },
    handleCurrentChange(val) {
      this.page1 = val;
      this.getNews1();
    },

    toPath(id, type) {
      this.$router.push("/newDetail?id=" + id + "&type=" + type);
    },
  },
  components: { Top, Bottom },
};
</script>
