<template>
	<div>
		<div class="top-nav flex-between flexCenter">
			<div class="top-nav-left flex-y-center">
				<img :src="info.logo" />
			</div>
			<div class="top-nav-right flex-around flex-y-center">
				<div :class="{'top-nav-active': navIndex == index}" v-for="(item, index) in navList" :key="index" @click.stop="selectNav(index, item.path)">{{item.title}}</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				info: {},
				navList: [{
						path: "/",
						title: "首页"
					},
					{
						path: "/companyIntroduction",
						title: "企业介绍"
					},
					{
						path: "/product/applet",
						title: "产品服务"
					},
					{
						path: "/partner",
						title: "合作客户"
					},
					{
						path: "/news",
						title: "资讯动态"
					},
					{
						path: "/contactUs",
						title: "联系我们"
					},
				],
				navIndex: this.$store.state.topTabIndex
			}
		},
		created() {
			this.info = JSON.parse(sessionStorage.getItem("topBottomInfo"));
		},
		methods: {
			selectNav(index) {
				this.$store.commit("changeTab", index)
				switch (index) {
					case 0:
						this.$router.push("/");
						break;
					case 1:
						this.$router.push("/companyIntroduction");
						break;
					case 2:
						this.$router.push("/product");
						break;
					case 3:
						this.$router.push("/partner");
						break;
					case 4:
						this.$router.push("/news");
						break;
					case 5:
						this.$router.push("/contactUs");
						break;
				}
			},
		}
	}
</script>

<style>
	.top-nav {
		width: 1320px;
		height: 100px;
		z-index: 999;
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		z-index: 999;
	}

	.top-nav-left {
		height: 100%;
	}

	.top-nav-left img {
		width: 195px;
		height: 70px;
	}

	.top-nav-right {
		width: 550px;
		height: 100%;
		color: #fff;
		font-size: 15px;
		cursor: pointer;
	}

	.top-nav-right>div {
		height: 25px;
		cursor: pointer;
	}

	.top-nav-active {
		position: relative;
	}

	.top-nav-active::after {
		content: "";
		position: absolute;
		bottom: -10px;
		left: 50%;
		height: 4px;
		width: 35px;
		border-radius: 5px;
		transform: translateX(-50%);
		background-color: #fff;
	}
</style>
